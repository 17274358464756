<template>
  <div v-loading="loading">
    <eden-page-header :title="'Rider Directory'" :subtitle="rider.name">
      <template slot="actions">
        <rider-actions
          v-if="allowAccessFor(['admin', 'operations'])"
          :rider="rider"
          @update="update"
        >
          <template slot="actions-trigger">
            <el-button type="plain">
              Actions <i class="eden-icon-arrow-down"></i>
            </el-button>
          </template>
        </rider-actions>
      </template>
    </eden-page-header>
    <eden-information-card>
      <template slot="content">
        <div class="rider-profile">
          <div class="rider-profile--summary">
            <eden-image :image-size="60" :image-url="rider.avatar" />
            <p class="name">{{ formatFullName(rider) }}</p>
            <span class="phone">
              {{ formatPhoneNumber(rider.phone_number) }}
            </span>
            <el-tag :class="'small'" :type="setType(rider.status)">{{
              formatText(rider.status)
            }}</el-tag>
            <el-button :type="'plain'" :size="'small'" @click="edit"
              >Edit Profile</el-button
            >
            <div class="progress-bar font-sm">
              <div class="progress-description">
                <span>0</span>
                of
                <span>0</span> orders completed today
              </div>

              <el-progress
                :show-text="false"
                :percentage="percent(0, 0)"
                :color="setPercentColor(0, 0)"
                status="success"
              ></el-progress>
            </div>
          </div>
          <el-table :data="riderInformation" :show-header="false">
            <el-table-column>
              <template slot-scope="scope">
                <span class="title font-xsm">{{ scope.row.label }}</span>
                <p
                  v-if="scope.row.type === 'services'"
                  class="title font-xsm is-flex is-flex-wrap"
                >
                  <el-tag
                    v-for="(value, index) in scope.row.text"
                    :key="index"
                    :type="setServiceType(value)"
                  >
                    {{ formatText(value) }}
                  </el-tag>
                </p>
                <p v-else>{{ scope.row.text }}</p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </eden-information-card>
    <rider-orders />
  </div>
</template>

<script>
import RiderActions from "@/components/Logistics/Directory/Riders/Rider/Actions/RiderActions";
import RiderOrders from "@/components/Logistics/Directory/Riders/Rider/RiderOrders.vue";

import riders from "@/requests/logistics/directory/riders";

export default {
  name: "DirectoryRider",
  components: {
    RiderActions,
    RiderOrders,
  },
  data() {
    return {
      loading: false,
      rider: {},
    };
  },
  computed: {
    riderId() {
      return this.$route.params.id;
    },
    riderInformation() {
      return [
        {
          label: "Company",
          text: this.rider.company && this.rider.company.name,
        },
        {
          label: "Services",
          text: this.rider.services,
          type: "services",
        },
        {
          label: "Location Area(s)",
          text:
            this.rider.location_areas &&
            this.rider.location_areas
              .map((area) => area.name)
              .toString()
              .split(",")
              .join(", "),
          type: "locations",
        },
        {
          label: "Added on",
          text: this.formatDateAndTime(this.rider.created_at, "dddd ddd, m, y"),
        },
      ];
    },
  },
  created() {
    this.getRider();
  },
  methods: {
    getRider() {
      this.loading = true;
      riders
        .get(this.riderId)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.rider = data[0];
          } else {
            this.$message.error(response.data.message);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    edit() {
      this.$router.push({
        name: "logistics.directory.rider-edit",
        params: { id: this.riderId },
      });
    },
    update(action) {
      if (action === "delete") {
        this.$router.push({
          name: "logistics.directory.index",
          query: { t: "riders" },
        });
      } else {
        this.rider.status =
          this.rider.status === "active" ? "inactive" : "active";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$profile-width: 320px;
.information-card {
  padding: 0;
  margin-bottom: 48px;

  &.no-border {
    border: none;
  }
}
.rider-profile {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &--summary {
    padding: 16px 0;
    max-width: $profile-width;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .name {
      font-weight: 500;
      font-size: 1.125rem;
      margin-top: 10px;
    }

    .phone {
      font-size: 0.875rem;
      font-weight: 400;
      color: var(--eden-grey-tertiary);
      display: block;
      margin: 7px 0;
    }

    .el-button {
      margin: 15px auto;
    }

    .progress {
      &-description {
        font-weight: 300;
        margin-bottom: 8px;
        span {
          font-weight: 400;
        }
      }
    }
  }

  .gardener-stats {
    max-width: 600px;
    width: 100%;
    .page--stat {
      margin: 0;
      padding: 0 14px 25px;
      border: 0;

      &:first-child {
        border-bottom: 1px solid #f0f4f2;
        margin-bottom: 15px;
      }
    }
  }
  @media (min-width: 1300px) {
    .rider-profile--summary {
      border-right: 1px solid #f0f4f2;
    }
  }
}
</style>
